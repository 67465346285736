.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.MuiPickersDay-root{
  color: #000 !important;
}

.MuiPickersDay-root.Mui-selected{
  color: #fff !important;
}
.MuiDayPicker-weekDayLabel{
  color: rgba(4,14,99,1) !important;
}
.MuiPickersCalendarHeader-label{
  color: rgba(4,14,99,1) !important;
  font-weight: bold;
}
.MuiPaginationItem-sizeMedium{
  color: #999 !important;
}
.css-levciy-MuiTablePagination-displayedRows{
  color: #000 !important;
}
.css-8f8t33-MuiSelect-select-MuiInputBase-input.css-8f8t33-MuiSelect-select-MuiInputBase-input.css-8f8t33-MuiSelect-select-MuiInputBase-input{
  color: #000 !important;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.MuiPaper-root{
  overflow: auto !important;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.MuiInputLabel-shrink{
  color: #000 !important;
}
select{
  color: #000 !important;
}
.MuiTextField-root input{
  color: #000 !important;
}
.rbc-calendar {
  min-height: 600px;
}
.MuiTab-textColorPrimary{
  color: #888;
}
.disabledButton:hover{
  cursor: not-allowed

}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
